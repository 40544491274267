import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import * as Widget from '../Components/Widget';
import * as Unstated from '../Utils/Unstated';
import { withPage } from './../../plugin/Page';
import * as L from '../Utils/Lang';
import PathPreFix from '../Utils/PathPrefixUtil';

function triggerSubmit(event){
    if(event.keyCode === 13){
        document.querySelector('[type="submit"]').click();
    }
}

class LoginPage extends React.Component{
    render () {
        let { appActions, navActions } = this.props;

        return (
        <div style={{
            backgroundColor:'#eee', 
            height:'100vh',
            display:'flex',
            justifyContent: 'center',
            alignItems: "center",
        }}>
            <Wrapper>
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Widget.BgImage 
                        image={PathPreFix("/images/sp-logo/Global-Star.png")} 
                        color="transparent"
                        extraCss="height:28px; width:32px;"/>
                    <h2 style={{color:'#a8c0c7',margin:'0 10px'}}>GLOBAL STAR</h2>
                </div>
                <Unstated.Form
                    key="login"
                    init={() => ({
                        values: {username: '',password:''},
                        editing: true
                    })}
                    submit={values => {
                        if(!values.username || !values.password){
                            return Promise.resolve({
                                values,
                                error:L.s('all-fields-required')
                            });
                        }
                        let {username,password} = values;
                        return appActions
                            .spLogin({username,password})
                            .then(() => { navActions.push('/')})
                            .catch(err => {
                                if(err.status === 401){
                                    return {error: L.s('error-login-message')}
                                }
                                return {error : L.s('error-message')};
                            })

                    }}
                >
                    {({values, errors, inputProps, editProps, submitProps}) => (
                        <div style={{
                            width: '100%',
                            margin: '0 auto',
                            padding: '5%'
                        }} >
                            <Widget.Col >
                                <Widget.Col  extraCss="margin-bottom: 10px;">
                                    <h4 style={{color:'white', fontWeight:'100', margin:"5px 0"}}>{L.s('username')} </h4>
                                    <FormInput
                                        placeholder="sample@gmail.com"
                                        {...inputProps.username}
                                        onKeyDown={triggerSubmit}
                                    />
                                </Widget.Col>
                                <Widget.Col  extraCss="margin-bottom: 10px;">
                                    <h4 style={{color:'white', fontWeight:'100', margin:"5px 0"}}>{L.s('password')} </h4>
                                    <FormInput
                                        placeholder="輸入密碼"
                                        type="password"
                                        {...inputProps.password}
                                        onKeyDown={triggerSubmit}
                                    />
                                </Widget.Col>
                                <Widget.Col extraCss="margin-top:15px">
                                    {submitProps.submitError && (
                                        <div style={{
                                            color: 'red',
                                            textAlign: "center",
                                            marginBottom: 10
                                        }}>
                                            {submitProps.submitError}
                                        </div>
                                    )}
                                    { submitProps.submitting ? (
                                        <Widget.Center>
                                            <Widget.Spinner />
                                        </Widget.Center>
                                    ):(
                                        <Widget.BlueButton 
                                            type="submit"
                                            onClick={submitProps.submit}
                                            disabled={ !submitProps.enabled || submitProps.submitting}
                                            extraCss="font-size:15px">
                                        {L.s('login')} 
                                        </Widget.BlueButton>
                                    )}
                                </Widget.Col>
                                
                            </Widget.Col>
                        </div>
                    )}

                </Unstated.Form>
            </Wrapper>
        </div>)
    }
}

const Wrapper = styled.div`
    background-color: #333333;
    padding:5%;
    width:450px;
    text-align:center;

    @media screen and (max-width:350px){
        width:100%
    }
`;
const FormInput = styled.input`
  flex: 1;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 8px 12px;
  height: 38px;
  font-weight: 200;
  font-size: 16px;
  color: #3b3b3b;

  :focus {
    outline: none;
  }
`;
export default withPage(
    connect(
        null,
        ActionCreator
    )(LoginPage)
)